<template>
  <a-row>
    <a-col style="margin-bottom: 10px" :span="6">
      {{ $t('login') }}:
    </a-col>
    <a-col style="margin-bottom: 10px" :span="18">
      {{ VM.state.meta.login }}
    </a-col>
    <a-col :span="6">
      {{ $t('clientinfo.password') }}: 
    </a-col>
    <a-col :span="18">
      <password :password="VM.state.meta.password"/>
    </a-col>
  </a-row>
</template>

<script>
import password from '@/components/password.vue';

export default {
	name: "access-manager",
	components: { password },
  props: { VM: { type: Object, required: true } }
}
</script>
